// Spinner.js
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3vh",
                marginTop: "15px",
            }}
        >
            <CircularProgress style={{ color: "black" }} />
        </div>
    );
};

export default Spinner;
