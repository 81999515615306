import {
    Box,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import RSVPSuccess from "./RSVPSuccess";
import sendUserData from "../api/sendUserData";
import SubmitButton from "./SubmitButton";
import Spinner from "./Spinner";
import CustomFormField from "./CustomFormField";

const WithoutCode = () => {
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [additionalAttendees, setAdditionalAttendees] = useState(0);
    const [willAttend, setWillAttend] = useState("Yes");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const [SubmitButtonDisabled, setSubmitButtonDisabled] = useState(true);

    // Media query check for the textfields:
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const isValidPhoneNumber = (phoneNumber) => {
        if (phoneNumber === "") {
            return false;
        }
        const kenyanPhoneNumberRegex = /^\+254\d{9}$/;
        return kenyanPhoneNumberRegex.test(phoneNumber);
    };
    const handleIncrement = () => {
        setAdditionalAttendees((prevAttendees) => prevAttendees + 1);
    };

    const handleDecrement = () => {
        if (additionalAttendees > 0) {
            setAdditionalAttendees((prevAttendees) => prevAttendees - 1);
        }
    };

    const handleRSVP = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!phoneNumber && !userName) {
            alert("Form cannot be empty.");
            setLoading(false);
            return;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            alert(
                "Phone number seems invalid. Provide the country code followed by the number.",
            );
            setLoading(false);
            return;
        }

        let data = {
            FullName: userName,
            PhoneNumber: phoneNumber,
            InviteCode: "null",
            IsAttending: willAttend === "Yes" ? true : false,
            NumberOfGuests: additionalAttendees,
        };

        try {
            const res = await sendUserData(data, false);

            if (res && res.status === 200) {
                setIsSubmitted(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return !isSubmitted ? (
        <Box
            sx={{
                my: {
                    xs: 2,
                    sm: 4,
                    md: 4,
                    lg: 4,
                },
                mx: {
                    xs: 3,
                },
                pt: {
                    sm: 2,
                },
                pb: { xs: 4 },
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                color: "black",
            }}
        >
            <Box component="form" onSubmit={(e) => e.preventDefault()}>
                <Grid container>
                    <Typography
                        sx={{
                            textAlign: isMobile ? "left" : "center",
                            mb: 2,
                        }}
                    >
                        Kindly fill in the form below to confirm your
                        availability.
                    </Typography>

                    <Grid
                        container
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                        }}
                    >
                        <Typography component="h4">Name:</Typography>
                        <CustomFormField
                            id="user-name"
                            name="name"
                            placeholderValue="Your name"
                            fieldValue={userName}
                            onChangeFunction={setUserName}
                        />
                    </Grid>

                    <Grid
                        container
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                        }}
                    >
                        <Typography component="h4">Phone No:</Typography>
                        <CustomFormField
                            id="user-phone-number"
                            name="phone-number"
                            placeholderValue="eg. +254712345678"
                            fieldValue={phoneNumber}
                            onChangeFunction={setPhoneNumber}
                        />
                    </Grid>

                    <Grid
                        container
                        sx={{
                            marginTop: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Typography component="h4">Will You Attend?</Typography>

                        <FormControl
                            sx={{
                                marginLeft: {
                                    xs: "50px",
                                    md: "30px",
                                    lg: "100px",
                                },
                                my: 0,
                            }}
                        >
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                }}
                            >
                                <FormControlLabel
                                    sx={{
                                        marginRight: {
                                            xs: "40px",
                                            sm: "30px",
                                            md: "20px",
                                            lg: "80px",
                                        },
                                    }}
                                    value="Yes"
                                    control={
                                        <Radio
                                            checked={willAttend === "Yes"}
                                            onChange={() =>
                                                setWillAttend("Yes")
                                            }
                                            sx={{
                                                backgroundColor: "trasparent",
                                                color: "#000000",
                                                "&.Mui-checked": {
                                                    color: "#000000",
                                                    backgroundColor:
                                                        "transparent",
                                                },
                                                width: {
                                                    xs: "22px",
                                                    sm: "22px",
                                                    md: "23px",
                                                }, // Increase width
                                                height: {
                                                    xs: "22px",
                                                    sm: "22.5px",
                                                    md: "22px",
                                                }, // Increase height
                                                marginRight: "10px",
                                            }}
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="No"
                                    control={
                                        <Radio
                                            checked={willAttend === "No"}
                                            onChange={() => setWillAttend("No")}
                                            sx={{
                                                backgroundColor: "transparent",
                                                color: "#000000",
                                                "&.Mui-checked": {
                                                    color: "#000000",
                                                    backgroundColor:
                                                        "transparent",
                                                },
                                                width: {
                                                    xs: "22px",
                                                    sm: "22.5px",
                                                    md: "23px",
                                                }, // Increase width
                                                height: {
                                                    xs: "22px",
                                                    sm: "22.5px",
                                                    md: "22px",
                                                }, // Increase height
                                                marginRight: "10px",
                                            }}
                                        />
                                    }
                                    label="No"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {willAttend === "Yes" ? (
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography component="h4">
                                Additional Attendees:
                            </Typography>

                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: {
                                        xs: "center",
                                    },
                                }}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "10px 10px",
                                        width: "fit-content",
                                    }}
                                >
                                    <Button
                                        onClick={handleDecrement}
                                        disabled={additionalAttendees === 0}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#000000", // Change the background color to black
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#333333", // Change the background color to a darker shade of black when hovered
                                                boxShadow: "none",
                                            },
                                            "&:active": {
                                                boxShadow: "none",
                                                backgroundColor: "#000000", // Keep the background color as black when active
                                            },
                                            "&:disabled": {
                                                backgroundColor: "#666666", // Change the background color to a lighter shade of black when disabled
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        -
                                    </Button>

                                    <Box
                                        component="span"
                                        sx={{
                                            padding: "4px 16px",
                                            margin: "0 10px",
                                            backgroundColor: "white",
                                            border: "1px solid transparent",
                                            borderRadius: "3px",
                                        }}
                                    >
                                        {additionalAttendees}
                                    </Box>

                                    <Button
                                        onClick={handleIncrement}
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#000000", // Change the background color to black
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#333333", // Change the background color to a darker shade of black when hovered
                                                boxShadow: "none",
                                            },
                                            "&:active": {
                                                boxShadow: "none",
                                                backgroundColor: "#000000", // Keep the background color as black when active
                                            },
                                            "&:disabled": {
                                                backgroundColor: "#666666", // Change the background color to a lighter shade of black when disabled
                                                color: "#FFFFFF",
                                            },
                                        }}
                                    >
                                        +
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                onChange={() =>
                                    setSubmitButtonDisabled(
                                        !SubmitButtonDisabled,
                                    )
                                }
                                sx={{
                                    marginRight: 1,
                                    backgroundColor: "transparent",
                                    color: "#000000", // Change the color to black
                                    "&.Mui-checked": {
                                        color: "#000000", // Change the color to a darker shade of black when checked
                                        backgroundColor: "transparent",
                                    },
                                    "& .MuiSvgIcon-root": { fontSize: 30 },
                                }}
                            />
                        }
                        label={
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >
                                By checking this box, you agree to our{" "}
                                <a
                                    href="https://www.freeprivacypolicy.com/live/87e69ae6-89a8-4d80-8dd3-eb7f478c7c6a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "underline",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Privacy Policy
                                </a>{" "}
                                and authorize us to send event-related SMS
                                updates.
                            </span>
                        }
                        sx={{
                            my: 3,
                            fontSize: 12,
                            fontWeight: "light",
                            display: "flex",
                        }}
                    />

                    {!loading ? (
                        <SubmitButton
                            text="RSVP"
                            submitFunction={handleRSVP}
                            disabled={SubmitButtonDisabled}
                        />
                    ) : (
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Spinner />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    ) : (
        <RSVPSuccess />
    );
};

export default WithoutCode;
