import { Box, Typography } from "@mui/material";

const RSVPSuccess = () => {
    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#ffffff",
            }}
        >
            <h2>Thank You.</h2>
            <Typography
                component="p"
                sx={{ display: "flex", justifyContent: "center" }}
            >
                Response submitted successfully.
            </Typography>
        </Box>
    );
};

export default RSVPSuccess;
