import { TextField, useMediaQuery, useTheme } from "@mui/material";

export default function CustomFormField({
    id,
    name,
    placeholderValue,
    fieldValue,
    onChangeFunction,
}) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

    return (
        <TextField
            type="text"
            id={id}
            name={name}
            placeholder={placeholderValue}
            value={fieldValue}
            onChange={(e) => onChangeFunction(e.target.value)}
            size={isLargeScreen ? "small" : "medium"}
            sx={{
                backgroundColor: "#D9D9D9",
                borderRadius: "3px",
                color: "black",
                border: "none",
                boxShadow: "none",
                "&:focus": {
                    outline: "none",
                    boxShadow: "none", // Remove outline and box shadow on focus
                },
                "&:hover": {
                    outline: "none",
                    border: "none", // Remove border on hover
                },
                "&:active": {
                    outline: "none",
                    border: "none", // Remove border on hover
                },
                padding: {
                    xs: {
                        px: 2,
                        py: 4,
                        pl: 4, // Increase left padding on smaller screens
                        my: 2, // Increase vertical margin on smaller screens
                    },
                    md: {
                        px: 4, // Increase padding on medium screens
                        py: 6, // Increase padding on medium screens
                        pl: 6, // Increase left padding on medium screens
                        my: 4, // Increase vertical margin on medium screens
                    },
                    lg: {
                        px: 6, // Increase padding on larger screens
                        py: 8, // Increase padding on larger screens
                        pl: 8, // Increase left padding on larger screens
                        my: 6, // Increase vertical margin on larger screens
                    },
                },
            }}
        />
    );
}
