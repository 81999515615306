import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Grid, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WithoutCode from "./components/WithoutCode";
import EventInfoMain from "./components/EventInfoMain";
import EventInfoSub from "./components/EventInfoSub";

const theme = createTheme({
    typography: {
        fontFamily: "Nunito",
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Grid
                component="main"
                sx={{
                    height: "100vh",
                }}
            >
                <CssBaseline />

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                    sx={{
                        backgroundColor: "#9caf88",
                        paddingX: {
                            xl: 10,
                        },
                    }}
                >
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <Grid>
                                            <EventInfoMain />
                                            <Grid
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: {
                                                        xs: "column",
                                                        sm: "row",
                                                    },
                                                    px: {
                                                        xs: 0,
                                                        sm: 2,
                                                        md: 4,
                                                    },
                                                }}
                                            >
                                                <EventInfoSub />
                                                <WithoutCode />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            />
                        </Routes>
                    </Router>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
