import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BlurhashComponent from "./BlurhashComponent";

const EventInfoMain = () => {
    const isSmallScreen = useMediaQuery("(max-width: 420px)");

    const [isLoaded, setIsLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isMedium = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        if (isMobile) {
            setImageSrc("hero-xs.jpg");
        } else if (isTablet) {
            setImageSrc("medium-min.jpg");
        } else if (isMedium) {
            setImageSrc("hero-xs.jpg");
        }
    }, [isMobile, isTablet, isMedium]);

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "row",
                px: {
                    sm: 3,
                    lg: 6,
                    xl: 20,
                },
                pt: {
                    sm: 3,
                    lg: 6,
                    xl: 20,
                },
                color: "black",
            }}
        >
            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                    minHeight: isSmallScreen ? "36vh" : "65vh",
                    borderRadius: { xs: 0, sm: 15, md: 15 },
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        backgroundPosition: isSmallScreen
                            ? "center center"
                            : "center top",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        position: "relative",
                        borderRadius: { xs: 0, sm: 15, md: 15 },
                        "& > img": {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        },
                    }}
                >
                    <BlurhashComponent
                        hash="L36[Eru%003E1%Mc]P?wO@Sh$*v}"
                        toDisplay={isLoaded}
                    />
                    <img
                        src={`/images/${imageSrc}`}
                        alt="about-us"
                        onLoad={() => setIsLoaded(true)}
                        style={{
                            display: !isLoaded ? "none" : "inline",
                            borderTopRightRadius: isMobile ? 0 : "20px",
                            borderBottomRightRadius: isMobile ? 0 : "20px",
                        }}
                    />
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    px: {
                        xs: 1,
                        sm: 2,
                    },
                    py: {
                        xs: 3,
                        sm: 0,
                    },
                }}
            >
                <Typography
                    component="h1"
                    variant={isMobile ? "h4" : "h2"}
                    sx={{
                        fontWeight: isMobile ? "bold" : "inherit",
                        fontFamily: "Great Vibes, cursive",
                        textAlign: "center",
                        fontSize: {
                            md: 70,
                        },
                    }}
                >
                    {" "}
                    {/* Reduce text size on mobile FONT --> great vibes */}
                    Joseph and Vivian
                </Typography>
                <Typography
                    component="h2"
                    variant={isMobile ? "h6" : "h4"}
                    sx={{
                        fontFamily: "Great Vibes, cursive",
                        my: 1,
                        textAlign: "center",
                    }}
                >
                    {" "}
                    {/* Reduce text size on mobile FONT --> great vibes */}
                    Together with our families warmly invite you to our
                </Typography>
                <Typography
                    component="h5"
                    variant={isMobile ? "subtitle1" : "h5"}
                    sx={{
                        fontFamily: "Cinzel Decorative, cursive",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                    }}
                >
                    {" "}
                    <b>WEDDING CEREMONY</b>
                </Typography>
                <Typography
                    component="h3"
                    variant={isMobile ? "subtitle1" : "h5"}
                    sx={{ fontFamily: "Cinzel, serif", textAlign: "center" }}
                >
                    {" "}
                    At 10AM on Saturday, 20th April, 2024.
                </Typography>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        component="h3"
                        variant={isMobile ? "subtitle1" : "h6"}
                        sx={{
                            fontFamily: "Cinzel, serif",
                            textAlign: "center",
                            pt: 2,
                        }}
                    >
                        {" "}
                        AT THE <b>HOUSE OF GRACE</b> CHURCH, THIKA ROAD (600m
                        BEHIND MOUNTAIN MALL, ROASTERS).
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EventInfoMain;
