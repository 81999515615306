import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function EventInfoSub() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Box
            sx={{
                my: isMobile ? 1 : 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "black",
                flex: 1,
                px: 2,
                pt: {
                    sm: 2,
                },
            }}
        >
            <Typography
                component="h2"
                sx={{
                    textDecoration: "underline",
                    fontFamily: "Cinzel, serif",
                    pt: {
                        md: 2,
                    },
                }}
            >
                <b>Reception:</b>
            </Typography>

            <Typography
                component="h3"
                variant={isMobile ? "subtitle1" : "h6"}
                sx={{ fontFamily: "Cinzel, serif", textAlign: "center" }}
            >
                {" "}
                Maline Gardens, Garden Estate, 8th Street on Marurui road.
            </Typography>

            <Grid
                sx={{
                    fontFamily: "Cinzel, serif",
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        textDecoration: "underline",
                        py: 1,
                    }}
                >
                    <b>For Support:</b>
                </Box>

                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ px: 1 }}>Paybill Number: </Typography>
                    <Typography>
                        <b>522522</b>
                    </Typography>
                </Grid>

                <Grid sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
                    <Typography sx={{ pr: 1 }}>Account Number: </Typography>
                    <Typography>
                        <b>1319120822</b>
                    </Typography>
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        py: 1,
                    }}
                >
                    Or Send Money to:
                </Box>

                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        pb: 2,
                    }}
                >
                    <Typography>
                        <b>+2547 22 595 557</b>
                    </Typography>
                    <Typography>Ngaruiya Wainaina</Typography>
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        textDecoration: "underline",
                        py: 1,
                    }}
                >
                    <b>Theme Colors:</b>
                </Box>

                <Grid sx={{ textAlign: "center", mb: 3 }}>
                    <Typography>Sage Green and Grey</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
