import axios from "axios";
import isDebug from "../config";

const url =
    "https://eventinvite-api.azurewebsites.net/api/AttendeeRsvp?code=RcT6KIs-CgiUEWkpMbIZhNF79fvL1Fr1IQYaDaNLyrKuAzFuOP7IAw==&hasCode=";

const sendUserData = async (data, hasCode) => {
    try {
        const response = await axios.post(`${url}${hasCode}`, data, {
            params: {
                isDebug: isDebug,
            },
        });
        return response;
    } catch (error) {
        alert(error.response.data.Message);
    }
};

export default sendUserData;
