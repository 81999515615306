import { Button, Grid } from "@mui/material";

const SubmitButton = ({ text, submitFunction, disabled }) => {
    return (
        <Grid
            container
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Button
                disabled={disabled}
                type="submit"
                variant="contained"
                sx={{
                    mt: 2,
                    minWidth: "120px",
                    minHeight: "50px",
                    fontWeight: "700",
                    backgroundColor: "#000000", // Change the background color to black
                    color: "#FFFFFF",
                    "&:hover": {
                        backgroundColor: "#333333", // Change the background color to a darker shade of black when hovered
                        boxShadow: "none",
                    },
                    "&:active": {
                        boxShadow: "none",
                        backgroundColor: "#000000", // Keep the background color as black when active
                    },
                    "&:disabled": {
                        backgroundColor: "#666666", // Change the background color to a lighter shade of black when disabled
                        color: "#FFFFFF",
                    },
                }}
                onClick={submitFunction}
                size="large"
            >
                {text}
            </Button>
        </Grid>
    );
};

export default SubmitButton;
